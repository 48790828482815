.App {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}


.block{
  display: flex;
  width: 960px;
  height: 540px;
}

.left{
  flex:2;
  height:10;
  font-size:50px;
  padding:40px;
  line-height: 3;
}

.right{
  flex:1;
  height:10;
  display: flex;
  align-items: center;
  justify-content: center;
}


.blockMobile{
  display: flex;
   align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 99vw;
  margin-top: -100px;
}

.wordsMobile{
  text-align: center;
  flex:1;
  font-size: 30px;
  padding-bottom: 20px;
  line-height: 1.2;
}

.appstoreMobile{
  flex:1;

}

.gifMobile{
  flex:2;
}

