@font-face {
  font-family: 'Apercu';
  src: local('Apercu'), url(./assets/apercu_regular-webfont.woff);
  src: local('Apercu'), url(./assets/apercu_regular-webfont.woff2);
}

body {
  margin: 0;
  font-family: "Apercu";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: rgb(0,255,0);

  min-height: 100vh;
  min-height: -webkit-fill-available;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
